<script>
	import '../app.postcss';
    import {AppBar, AppShell, Avatar, initializeStores, Drawer, getDrawerStore} from "@skeletonlabs/skeleton";
    import Navigation from "$lib/components/Navigation.svelte";
    import {fly, fade} from "svelte/transition";

    initializeStores();

    const drawerStore = getDrawerStore();
    function drawerOpen() {
        drawerStore.open();
    }

    export let data
</script>

<Drawer>
    <h2 class="p-4">Navigation</h2>
    <hr />
    <Navigation />
</Drawer>

<AppShell slotSidebarLeft="bg-surface-500/5 w-0 md:w-52">
     <svelte:fragment slot="header">

        <AppBar transitionIn="{fade}"
                gridColumns="grid-cols-3"
                slotDefault="place-self-center"
                slotTrail="place-content-end"
        >
            <svelte:fragment slot="lead">
                <button aria-label="Menü" type="button" class="btn btn-sm mr-4 md:hidden" on:click={drawerOpen}>
                    <span>
                        <svg viewBox="0 0 100 80" class="fill-token w-4 h-4">
                            <rect width="100" height="20" />
                            <rect y="30" width="100" height="20" />
                            <rect y="60" width="100" height="20" />
                        </svg>
                    </span>
                </button>

            </svelte:fragment>

            <strong class="text-xl uppercase">Neujeffski</strong>
            <svelte:fragment slot="trail">

                <Avatar src="/images/lars_neujeffski.jpg"
                        initials="LN" width="w-10" background="bg-primary-500"></Avatar>
            </svelte:fragment>
        </AppBar>

    </svelte:fragment>
    <svelte:fragment slot="sidebarLeft">
        <Navigation/>
    </svelte:fragment>
    <!-- (sidebarLeft) -->
    <!-- (sidebarRight) -->
    <!-- (pageHeader) -->
    <!-- Router Slot -->
    {#key data.url}

        <div in:fly={{ x: -200, duration: 270, delay: 270 }}
             out:fly={{x: 200, duration: 270, }}
        >
            <slot />
        </div>
    {/key}
</AppShell>