<script>
    import {getDrawerStore} from "@skeletonlabs/skeleton";

    const drawerStore = getDrawerStore();

    function drawerClose() {
        drawerStore.close();
    }
</script>

<nav class="list-nav p-4">
    <ul>
        <li><a href="/" on:click={drawerClose}>Homepage</a></li>
        <li><a href="/about" on:click={drawerClose}>Über mich</a></li>
        <li><a href="/contact" on:click={drawerClose}>Kontakt</a></li>
    </ul>
</nav>